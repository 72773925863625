import React from "react"
import Layout from "../components/layout/layout"
import { graphql } from "gatsby"
import Hero from "../components/layout/hero"
import Navbar from "../components/layout/navbar"
import WoodlandEntry from "../components/services/woodland-maintenance"
import SEO from "../components/seo"

export default ({ data }) => {
  return(
    <Layout>
      <SEO
        title="Woodland Maintenance | Caledonian Tree Services"
        canonicalUrl={data.site.siteMetadata.siteUrl + "/contact-success/"}
        description=""
      />
      <Hero
        hero={data.hero.desktopImage.fluid}
        heroMobile={data.heroMobile.mobileImage.fluid}
        logo={data.logo.childImageSharp.fixed}
        logoMobile={data.logoMobile.childImageSharp.fixed}
        arb={data.arb.childImageSharp.fixed}
        strapline="Woodland Maintenance"
        straplineMobile="Woodland <br />Maintenance"/>
      <Navbar/>
      <section className="section">
        <div className="container content-container">
          {data.allContentfulWoodlandMaintenance.edges.map(({ node }, index) => (
            <WoodlandEntry key={index} image={node.image} title={node.title} link={node.link} text={node.text.childMarkdownRemark.html} index={index} />
          ))}
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  {
   allContentfulWoodlandMaintenance(sort: { fields: [order], order: ASC }) {
    edges{
      node{
        image{
          image{
            file{
              url
            }
          }
          altText
        }
        title
        text{
          childMarkdownRemark{
            html
          }
        }
      }
    }
  }
  hero: contentfulHeroImage(desktopImage: {title: {eq: "woodland-hero"}}) {
    desktopImage{
      fluid{
        src
      }
    }
  }
  heroMobile: contentfulHeroImage(mobileImage: {title: {eq: "woodland-hero-mobile"}}) {
    mobileImage {
      fluid {
        src
      }
    }
  }
  logo: file(relativePath: { eq: "logo.png" }) {
    childImageSharp {
      fixed(width: 150, height: 150) {
        ...GatsbyImageSharpFixed_withWebp_noBase64
      }
    }
  }
  logoMobile: file(relativePath: { eq: "logoMobile.png" }) {
    childImageSharp {
      fixed(width: 50, height: 50) {
        ...GatsbyImageSharpFixed_withWebp_noBase64
      }
    }
  }
  site {
    siteMetadata {
      siteUrl
    }
  }
  arb: file(relativePath: { eq: "ARB-Approved2.png" }) {
    childImageSharp {
      fixed(width: 125, height: 150) {
        ...GatsbyImageSharpFixed_withWebp_noBase64
      }
    }
  }
}
`
